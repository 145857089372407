(function ($) {
  $(window).load(function () {

    // owl-carousel
    $('.owl-carousel:not(.slider)').owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: true,

      // drag
      mouseDrag: false
    })

    // slider
    $('.slider').owlCarousel({
      items: 1,
      margin: 20,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: false,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3,
          nav: true
        }
      }
    });

    // form-sent
    setTimeout(() => {

      var locationSearch = '' + document.location.search;
      if (
        (-1 === locationSearch.indexOf('form[sent]') && -1 === decodeURIComponent(locationSearch).indexOf('form[sent]'))
        || -1 === document.location.hash.indexOf('#form')
      ) {
        return;
      }

      var $headerHeight = $('.header');
      var $message = $('.form-success-message');

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $('html, body').animate({ scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30 });
    }, 500);

  });

  $(document).ready(function () {

    // sticky
    require('../../vendor/w3media/framework/assets/js/V2/body-sticky-class');

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

    // search-icon
    $('.search-icon').click(function() {
      $(this).parent().find('.form-control').toggleClass('show');
      $(this).toggleClass('hide');
    });

    // faq fix
    let faqlinks = document.querySelectorAll(".faq-category a");
    faqlinks.forEach((link) => {
      if (link.id) {
        link.id = ""
        link.href = "#";
      }
    });

    // scroll-to-content
    $('.js-3w-scroll-to-content').on('click', function (event) {
      event.preventDefault();
      var identifier = $(this).attr('data-target');
      var $element = $(identifier);

      if ($element) {
        $('html, body').animate({ scrollTop: $element.offset().top }, 500);
      }
    });

    // scroll to top on book page
    if ( $('body.accommodation-book').length > 0 ) {
      setInterval(() => {
        if ( $('.page-block.book').find('div[data-type-active="booking_done"]').length > 0 ) {
          $('html, body').animate({ scrollTop: 0 }, 500);
        }
      }, 500);
    }

    // Get site language
    const siteLanguage = document.documentElement.lang;

    switch (siteLanguage) {
      case 'nl':
        var msgs = {
          required: "Dit is een verplicht veld.",
          remote: "Gelieve dit veld te corrigeren.",
          email: "Gelieve een geldig e-mailadres in te voeren.",
          url: "Gelieve een geldige URL in te voeren.",
          date: "Gelieve een geldige datum in te voeren.",
          dateISO: "Gelieve een geldige datum in te voeren (ISO).",
          number: "Gelieve een geldig nummer in te voeren.",
          digits: "Gelieve alleen cijfers in te voeren.",
          creditcard: "Gelieve een geldig creditcardnummer in te voeren.",
          equalTo: "Gelieve dezelfde waarde opnieuw in te voeren.",
          accept: "Gelieve een waarde met een geldige extensie in te voeren.",
          maxlength: $mcj.validator.format("Gelieve niet meer dan {0} tekens in te voeren."),
          minlength: $mcj.validator.format("Gelieve ten minste {0} tekens in te voeren."),
          rangelength: $mcj.validator.format("Gelieve een waarde in te voeren tussen {0} en {1} tekens lang."),
          range: $mcj.validator.format("Gelieve een waarde in te voeren tussen {0} en {1}."),
          max: $mcj.validator.format("Gelieve een waarde in te voeren die minder dan of gelijk aan {0} is."),
          min: $mcj.validator.format("Gelieve een waarde in te voeren die groter dan of gelijk aan {0} is."),
          mc_birthday: "Gelieve een geldige maand en dag in te voeren.",
          mc_date: "Gelieve een geldige datum in te voeren.",
          mc_phone: "Gelieve een geldig telefoonnummer in te voeren.",
        };

        var label = {
          first_name: 'Voornaam',
          last_name: 'Achternaam',
          email_address: 'E-mail adres',
          submit: 'Inschrijven',
        };

        var tag = '8114357';

        var customText = 'Je ontvangt daarna een e-mail met een activatieknop om je inschrijving te bevestigen. Geen bericht ontvangen, controleer je SPAM folder.';

        break;
      case 'it':
        var msgs = {
          required: "Questo campo è obbligatorio.",
          remote: "Si prega di correggere questo campo.",
          email: "Si prega di inserire un indirizzo email valido.",
          url: "Si prega di inserire un URL valido.",
          date: "Si prega di inserire una data valida.",
          dateISO: "Si prega di inserire una data valida (ISO).",
          number: "Si prega di inserire un numero valido.",
          digits: "Si prega di inserire solo cifre.",
          creditcard: "Si prega di inserire un numero di carta di credito valido.",
          equalTo: "Si prega di inserire lo stesso valore di nuovo.",
          accept: "Si prega di inserire un valore con un'estensione valida.",
          maxlength: $mcj.validator.format("Si prega di non inserire più di {0} caratteri."),
          minlength: $mcj.validator.format("Si prega di inserire almeno {0} caratteri."),
          rangelength: $mcj.validator.format("Si prega di inserire un valore tra {0} e {1} caratteri."),
          range: $mcj.validator.format("Si prega di inserire un valore tra {0} e {1}."),
          max: $mcj.validator.format("Si prega di inserire un valore minore o uguale a {0}."),
          min: $mcj.validator.format("Si prega di inserire un valore maggiore o uguale a {0}."),
          mc_birthday: "Si prega di inserire un mese e un giorno validi.",
          mc_date: "Si prega di inserire una data valida.",
          mc_phone: "Si prega di inserire un numero di telefono valido.",
        };

        var label = {
          first_name: 'Nome',
          last_name: 'Cognome',
          email_address: 'Indirizzo e-mail',
          submit: 'Iscriversi',
        };

        var tag = '8114361';

        var customText = 'Riceverete quindi un\'e-mail con un pulsante di attivazione per confermare l\'abbonamento. Nessun messaggio ricevuto, controllare la cartella SPAM.';

        break;
      case 'de':
        var msgs = {
          required: "Dieses Feld ist erforderlich.",
          remote: "Bitte beheben Sie dieses Feld.",
          email: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
          url: "Bitte geben Sie eine gültige URL ein.",
          date: "Bitte geben Sie ein gültiges Datum ein.",
          dateISO: "Bitte geben Sie ein gültiges Datum (ISO) ein.",
          number: "Bitte geben Sie eine gültige Nummer ein.",
          digits: "Bitte geben Sie nur Ziffern ein.",
          creditcard: "Bitte geben Sie eine gültige Kreditkartennummer ein.",
          equalTo: "Bitte geben Sie denselben Wert erneut ein.",
          accept: "Bitte geben Sie einen Wert mit einer gültigen Erweiterung ein.",
          maxlength: $mcj.validator.format("Bitte geben Sie nicht mehr als {0} Zeichen ein."),
          minlength: $mcj.validator.format("Bitte geben Sie mindestens {0} Zeichen ein."),
          rangelength: $mcj.validator.format("Bitte geben Sie einen Wert zwischen {0} und {1} Zeichen ein."),
          range: $mcj.validator.format("Bitte geben Sie einen Wert zwischen {0} und {1} ein."),
          max: $mcj.validator.format("Bitte geben Sie einen Wert kleiner oder gleich {0} ein."),
          min: $mcj.validator.format("Bitte geben Sie einen Wert größer oder gleich {0} ein."),
          mc_birthday: "Bitte geben Sie einen gültigen Monat und Tag ein.",
          mc_date: "Bitte geben Sie ein gültiges Datum ein.",
          mc_phone: "Bitte geben Sie eine gültige Telefonnummer ein.",
        };

        var label = {
          first_name: 'Vorname',
          last_name: 'Nachname',
          email_address: 'E-Mail-Adresse',
          submit: 'Einschreiben',
        };

        var tag = '8114358';

        var customText = 'Sie erhalten dann eine E-Mail mit einer Aktivierungsschaltfläche zur Bestätigung Ihres Abonnements. Keine Nachricht erhalten, bitte prüfen Sie Ihren SPAM-Ordner.';

        break;
      case 'fr':
        var msgs = {
          required: "Ce champ est requis.",
          remote: "Veuillez corriger ce champ.",
          email: "Veuillez saisir une adresse e-mail valide.",
          url: "Veuillez saisir une URL valide.",
          date: "Veuillez saisir une date valide.",
          dateISO: "Veuillez saisir une date valide (ISO).",
          number: "Veuillez saisir un numéro valide.",
          digits: "Veuillez ne saisir que des chiffres.",
          creditcard: "Veuillez saisir un numéro de carte de crédit valide.",
          equalTo: "Veuillez saisir la même valeur à nouveau.",
          accept: "Veuillez saisir une valeur avec une extension valide.",
          maxlength: $mcj.validator.format("Veuillez ne pas saisir plus de {0} caractères."),
          minlength: $mcj.validator.format("Veuillez saisir au moins {0} caractères."),
          rangelength: $mcj.validator.format("Veuillez saisir une valeur entre {0} et {1} caractères."),
          range: $mcj.validator.format("Veuillez saisir une valeur entre {0} et {1}."),
          max: $mcj.validator.format("Veuillez saisir une valeur inférieure ou égale à {0}."),
          min: $mcj.validator.format("Veuillez saisir une valeur supérieure ou égale à {0}."),
          mc_birthday: "Veuillez saisir un mois et un jour valides.",
          mc_date: "Veuillez saisir une date valide.",
          mc_phone: "Veuillez saisir un numéro de téléphone valide.",
        };

        var label = {
          first_name: 'Prénom',
          last_name: 'Nom de famille',
          email_address: 'Adresse e-mail',
          submit: 'S\'abonner',
        };

        var tag = '8114359';

        var customText = 'Vous recevrez ensuite un e-mail avec un bouton d\'activation pour confirmer votre inscription. Aucun message reçu, veuillez vérifier votre dossier SPAM.';

        break;
      default:
        var msgs = {
          required: "This field is required.",
          remote: "Please fix this field.",
          email: "Please enter a valid email address.",
          url: "Please enter a valid URL.",
          date: "Please enter a valid date.",
          dateISO: "Please enter a valid date (ISO).",
          number: "Please enter a valid number.",
          digits: "Please enter only digits.",
          creditcard: "Please enter a valid credit card number.",
          equalTo: "Please enter the same value again.",
          accept: "Please enter a value with a valid extension.",
          maxlength: $mcj.validator.format("Please enter no more than {0} characters."),
          minlength: $mcj.validator.format("Please enter at least {0} characters."),
          rangelength: $mcj.validator.format("Please enter a value between {0} and {1} characters long."),
          range: $mcj.validator.format("Please enter a value between {0} and {1}."),
          max: $mcj.validator.format("Please enter a value less than or equal to {0}."),
          min: $mcj.validator.format("Please enter a value greater than or equal to {0}."),
          mc_birthday: "Please enter a valid month and day.",
          mc_date: "Please enter a valid date.",
          mc_phone: "Please enter a valid phone number.",
        };

        var label = {
          first_name: 'First Name',
          last_name: 'Last Name',
          email_address: 'Email Address',
          submit: 'Subscribe',
        };

        var tag = '8114362';

        var customText = 'You will then receive an email with an activation button to confirm your subscription. No message received, please check your SPAM folder.';

        break;
    }
    
    // translate mailchimp messages
    $mcj.extend($mcj.validator.messages, msgs);

    // translate mailchimp inputs
    $('#mce-FNAME').attr('placeholder', label.first_name);
    $('#mce-LNAME').attr('placeholder', label.last_name);
    $('#mce-EMAIL').attr('placeholder', label.email_address);
    $('#mc-embedded-subscribe').attr('value', label.submit);
    // toggle tags
    $('#mce-tags').attr('value', tag);
    // custom-text
    $('#mce-custom-text').text(customText);

  });
})(jQuery);
